<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiClockOutline }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn color="accent" rounded @click.prevent="addnewClock">
        <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
        <span>Create New</span>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4 lg3 v-for="(clock, index) in clocks" :key="index">
          <v-card class="text-xs-center ma-3" outlined>
            <v-responsive class="pa-2" align="center">
              <v-avatar color="secondary" size="54">
                <span class="white--text text-h5"> {{ usersInitials(clock) }}</span>
              </v-avatar>
            </v-responsive>
            <v-card-text>
              <div class="text-center text-h6 mb-1">{{ clock.first_name + ' ' + clock.last_name }}</div>
              <div class="text-center text-x-small mb-1">{{ current_date }}</div>
              <div id="time" class="mt-4">{{ current_time }}</div>
              <div v-show="clock.time_in" class="text-center font-weight-bold text-x-small info--text my-2">
                Time In: {{ timeFormat(clock.time_in) }}
              </div>
              <div v-show="clock.time_out" class="text-center font-weight-bold text-x-small error--text my-2">
                Time Out: {{ timeFormat(clock.time_out) }}
              </div>
              <div v-show="clock.duration" class="text-center font-weight-bold text-x-small my-2">
                Duration: {{ clock.duration }}
              </div>
            </v-card-text>

            <v-layout justify-center>
              <v-card-actions>
                <div class="mt-4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click.prevent="showClockIn(clock)"
                        dark
                        class="mx-1"
                        fab
                        small
                      >
                        <v-icon size="20">{{ icons.mdiClockIn }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Clock In</span>
                  </v-tooltip>
                </div>
                <div class="mt-4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click.prevent="showClockOut(clock)"
                        dark
                        class="mx-1"
                        fab
                        small
                      >
                        <v-icon size="20">{{ icons.mdiClockOut }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Clock Out</span>
                  </v-tooltip>
                </div>
              </v-card-actions>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="timesheets"
        :items-per-page="10"
        :search="search"
        sort-by="user_id"
        item-key="clock_id"
        class="elevation-2"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="page-title">
              <v-icon class="secondary--text" left light>{{ icons.mdiClockTimeFourOutline }}</v-icon>
              Timesheets
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider class="mb-2"></v-divider>
          <v-text-field
            color="accent"
            class="mx-2"
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(timesheet, index) in items" :key="index">
              <td>
                <v-icon class="mr-1">{{ icons.mdiAccountTie }}</v-icon
                >{{ timesheet.first_name }}
              </td>
              <td>{{ timesheet.last_name }}</td>
              <td>Role</td>
              <td>{{ timesheet.activity }}</td>
              <td>{{ timesheet.entry_date }}</td>
              <td>{{ timesheet.time_in }}</td>
              <td>{{ timesheet.time_out }}</td>
              <td>{{ timesheet.duration }}</td>
              <td>{{ timesheet.notes }}</td>

              <td>
                <v-btn color="primary" @click.prevent="editTimeClock(timesheet)" class="mx-1" fab x-small dark>
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn>
                <v-btn @click.prevent="deleteTimesheet(timesheet.clock_id)" color="error" fab x-small dark>
                  <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>

        <template v-slot:no-data>
          <v-btn color="accent" @click="showTimesheets"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <add-clock
      :dialog.sync="showAddClockDialog"
      @open-dialog="showAddClockDialog = true"
      @close-dialog="showAddClockDialog = false"
    />
    <edit-clock
      :dialog.sync="showEditClockDialog"
      @open-dialog="showEditClockDialog = true"
      :timesheet="timesheet"
      @close-dialog="showEditClockDialog = false"
    />
    <clock-in
      :dialog.sync="showEditClockInDialog"
      @open-dialog="showEditClockInDialog = true"
      :timesheet="user"
      @close-dialog="showEditClockInDialog = false"
    />
    <clock-out
      :dialog.sync="showEditClockOutDialog"
      @open-dialog="showEditClockOutDialog = true"
      :user="user"
      @close-dialog="showEditClockOutDialog = false"
    />
  </v-card>
</template>

<script>
import AddClock from '@/views/clock/AddManualClock.vue'
import EditClock from '@/views/clock/EditManualClock.vue'
import ClockIn from '@/views/clock/ClockIn.vue'
import ClockOut from '@/views/clock/ClockOut.vue'
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiAccountCircle,
  mdiClockOutline,
  mdiClockIn,
  mdiClockOut,
  mdiClockTimeFourOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()

export default {
  components: {
    AddClock,
    EditClock,
    ClockIn,
    ClockOut,
  },
  created: function () {
    this.getClocks()
    this.showTimesheets()
    window.mitt.on('loadClocks', () => {
      this.getClocks()
      this.showTimesheets()
    })
    this.current_date = moment().format('l')
    this.current_time = moment().format('HH:mm:ss')
    setInterval(() => {
      this.updateClocks(), 1 * 1000
    })
  },

  data() {
    return {
      headers: [
        {
          text: 'First Name',
          value: 'first_name',
        },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Role', value: 'user_type' },
        { text: 'Activity', value: 'activity' },
        { text: 'Entry Date', value: 'entry_date' },
        { text: 'Time In', value: 'time_in' },
        { text: 'Time Out', value: 'time_out' },
        { text: 'Duration', value: 'duration' },
        { text: 'Note', value: 'notes' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: '',
      clocks: [],
      timesheets: [],
      user: {},
      timesheet: {},
      current_date: null,
      current_time: null,

      showAddClockDialog: false,
      showEditClockDialog: false,
      showEditClockInDialog: false,
      showEditClockOutDialog: false,
      icons: {
        mdiAccountTie,
        mdiMagnify,
        mdiDotsVertical,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiAccountCircle,
        mdiClockOutline,
        mdiClockIn,
        mdiClockOut,
        mdiClockTimeFourOutline,
      },
    }
  },

  methods: {
    getClocks: function () {
      this.$store
        .dispatch('clock/fetchTimesheets')
        .then(response => {
          this.clocks = this.fetchTimesheets
        })
        .catch(err => {
          console.log(err)
        })
    },
    showTimesheets: function () {
      this.$store
        .dispatch('clock/getTimesheets')
        .then(response => {
          this.timesheets = this.fetchTimesheets
        })
        .catch(err => {
          console.log(err)
        })
    },

    getTimesheets: function () {
      this.$store
        .dispatch('clock/editTimesheets', {
          user_id: this.userid,
          entry_date: this.timesheet.entry_date,
        })
        .then(response => {
          this.timesheet = this.fetchTimesheets
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteTimesheet: function (id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`clock/deleteTimesheet`, { clock_id: id })
              .then(response => {
                this.showTimesheets()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
    timeFormat: function (value) {
      return moment(value).format('HH:mm')
    },

    updateClocks: function () {
      this.current_date = moment().format('l')
      this.current_time = moment().format('HH:mm:ss')
    },

    usersInitials(user) {
      const name = user.username.split(' ')
      return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`
    },
    addnewClock: function () {
      this.showAddClockDialog = true
    },

    showClockIn: function (user) {
      this.user = Object.assign({}, user)
      this.showEditClockInDialog = true
    },
    showClockOut: function (user) {
      this.user = Object.assign({}, user)
      this.showEditClockOutDialog = true
    },

    editTimeClock: function (timesheet) {
      this.timesheet = Object.assign({}, timesheet)
      this.showEditClockDialog = true
    },
  },
  watch: {},

  computed: {
    ...mapGetters({
      fetchTimesheets: 'clock/fetchTimesheets',
    }),
  },
}
</script>

<style>
</style>