<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">Add time manually</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col md="12" cols="12">
                <v-layout>
                  <v-autocomplete
                    v-model="timesheet.user_id"
                    :items="users"
                    item-value="user_id"
                    item-text="username"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Technician"
                    :prepend-inner-icon="icons.mdiAccountClock"
                    single-line
                  ></v-autocomplete>
                  <div class="mx-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="info"
                          v-bind="attrs"
                          v-on="on"
                          @click.prevent="$router.push({ name: 'addnewuser' })"
                          dark
                          class="mx-1"
                          fab
                          small
                        >
                          <v-icon size="20">{{ icons.mdiPlus }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Add New Technician</span>
                    </v-tooltip>
                  </div>
                </v-layout>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  color="secondary"
                  v-model="timesheet.activity"
                  label="Activity"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-dialog
                  ref="entry_date"
                  v-model="entry_date"
                  :return-value.sync="timesheet.entry_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="timesheet.entry_date"
                      label="Date"
                      color="secondary"
                      :prepend-inner-icon="icons.mdiCalendarCheck"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="timesheet.entry_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="entry_date = false"> Cancel </v-btn>
                    <v-btn text color="primary" @click="$refs.entry_date.save(timesheet.entry_date)"> OK </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field
                  color="secondary"
                  readonly
                  v-model="timesheet.duration"
                  label="Duration"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field
                  color="secondary"
                  v-model="timesheet.time_in"
                  @change="computeDuration"
                  label="Time In"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field
                  color="secondary"
                  @change="computeDuration"
                  v-model="timesheet.time_out"
                  label="Time Out"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <v-textarea outlined label="Notes" v-model="timesheet.notes"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click.prevent="saveManualClock">
            <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import {
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiDeleteForeverOutline,
  mdiClose,
  mdiCash,
  mdiAccountClock,
  mdiClockTimeFourOutline,
  mdiCalendarCheck,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    dialog: Boolean,
  },

  created: function () {
    this.getUsers()
    this.current_time = moment().format('YYYY-MM-DD HH:mm:ss')
    this.timesheet.time_in = this.current_time
    this.timesheet.time_out = this.current_time
  },
  data() {
    return {
      users: [],
      clocks: [],
      roles: [],
      timesheet: {
        user_id: '',
        activity: '',
        entry_date: '',
        time_in: '',
        time_out: '',
        notes: '',
        duration: '',
        status: 1,
      },
      entry_date: false,
      current_date: null,
      current_time: null,

      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
        mdiAccountClock,
        mdiClockTimeFourOutline,
        mdiCalendarCheck,
      },
    }
  },
  methods: {
    getUsers: function () {
      this.$store
        .dispatch('user/fetchUsers')
        .then(response => {
          this.users = this.fetchUsers
        })
        .catch(err => {
          console.log(err)
        })
    },

    getClocks: function () {
      this.$store
        .dispatch('clock/fetchTimesheets')
        .then(response => {
          this.clocks = this.fetchUsers
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveManualClock: function () {
      this.$store
        .dispatch(`clock/storeNewTimesheet`, this.timesheet)
        .then(response => {
          window.mitt.emit('loadClocks', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },
    computeDuration: function () {
      const timeIn = moment(this.timesheet.time_in, 'YYYY-MM-DD HH:mm')
      const timeOut = moment(this.timesheet.time_out, 'YYYY-MM-DD HH:mm')
      const duration = moment.duration(timeOut.diff(timeIn))

      //Get Days
      const days = duration.days()
      //Get hours
      const hours = duration.asHours()
      this.timesheet.duration = hours.toFixed(2)
    },
    resetForm: function () {
      this.timesheet = {}
    },
    close() {
      this.$emit('close-dialog')
    },
  },

  computed: {
    ...mapGetters({
      fetchUsers: 'user/fetchUsers',
      fetchTimesheets: 'clock/fetchTimesheets',
    }),
  },
}
</script>

<style>
</style>