<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">Clock Out</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-form class="multi-col-validation">
            <v-row>
              <v-layout align="center">
                <div id="time" class="mt-4 primary--text">{{ current_time }}</div>
              </v-layout>
              <v-col md="12" cols="12">
                <v-autocomplete
                  v-model="timesheet.user_id"
                  :items="users"
                  item-value="user_id"
                  item-text="username"
                  outlined
                  color="secondary"
                  dense
                  chips
                  deletable-chips
                  small-chips
                  label="Technician"
                  :prepend-inner-icon="icons.mdiAccountClock"
                  single-line
                ></v-autocomplete>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <v-text-field
                  color="secondary"
                  v-model="timesheet.activity"
                  label="Activity"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field
                  color="secondary"
                  readonly
                  v-model="timesheet.entry_date"
                  label="Date"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field
                  color="secondary"
                  v-model="timesheet.time_out"
                  label="Time Out"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <v-textarea outlined label="Notes" v-model="timesheet.notes"></v-textarea
              ></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" rounded @click.prevent="saveClockOut">
            <v-icon right dark>{{ icons.mdiClockOut }}</v-icon>
            <span>Clock Out</span>
          </v-btn>

          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import {
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiDeleteForeverOutline,
  mdiClose,
  mdiCash,
  mdiAccountClock,
  mdiClockTimeFourOutline,
  mdiCalendarCheck,
  mdiClockIn,
  mdiClockOut,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    dialog: Boolean,
    user: Object,
  },
  created: function () {
    this.getUsers()
    this.getTimesheets()
    window.mitt.on('loadTimesheets', () => {
      this.getTimesheets()
    })

    this.current_date = moment().format('YYYY-MM-DD')
    this.current_time = moment().format('YYYY-MM-DD HH:mm:ss')

    setInterval(() => {
      this.updateClocks(), 1 * 1000
      this.timesheet.entry_date = this.current_date
      this.timesheet.time_out = this.current_time
    })
  },
  data() {
    return {
      users: [],
      timesheet: {},
      current_date: null,
      current_time: null,

      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
        mdiAccountClock,
        mdiClockTimeFourOutline,
        mdiCalendarCheck,
        mdiClockIn,
        mdiClockOut,
      },
    }
  },
  methods: {
    getUsers: function () {
      this.$store
        .dispatch('user/fetchUsers')
        .then(response => {
          this.users = this.fetchUsers
        })
        .catch(err => {
          console.log(err)
        })
    },
    getTimesheets: function () {
      this.$store
        .dispatch('clock/editTimesheets', {
          user_id: this.userid,
          entry_date: this.timesheet.entry_date,
        })
        .then(response => {
          this.timesheet = this.fetchTimesheets
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveClockOut: function () {
      this.$store
        .dispatch(`clock/updateNewTimesheet`, {
          user_id: this.user.user_id,
          entry_date: this.timesheet.entry_date,
          data: {
            user_id: this.user.user_id,
            entry_date: this.timesheet.entry_date,
            time_out: this.timesheet.time_out,
            activity: this.timesheet.activity,
            notes: this.timesheet.notes,
          },
        })
        .then(response => {
          window.mitt.emit('loadClocks', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },

    updateClocks: function () {
      this.current_date = moment().format('YYYY-MM-DD')
      this.current_time = moment().format('YYYY-MM-DD HH:mm:ss')
    },

    close() {
      this.$emit('close-dialog')
    },
  },

  watch: {
    userid() {
      window.mitt.emit('loadTimesheets')
    },
  },

  computed: {
    ...mapGetters({
      fetchUsers: 'user/fetchUsers',
      fetchTimesheets: 'clock/fetchTimesheets',
    }),
    userid() {
      return this.user.user_id
    },
  },
}
</script>

<style>
</style>